import moment from 'moment';
import 'moment/locale/pt-br';


// function atividades por dia
export const ativsByDay =  (atividades) => {

  // reduce to array containing all events
  let result = atividades.reduce((val, element) => {        
    if (element.eventos) {
      
      element.eventos.forEach(e => {
        val = [...val, { 
          // key: element.atividadeId,
          // id: element.atividadeId,

          key: `${element.atividadeId}+${e.local}+${e.dateTime.toDate()}`,
          id:  `${element.atividadeId}+${e.local}+${e.dateTime.toDate()}`,

          // atividadeId: element.atividadeId,
          atividade: element, 
          // atividade: { ...element, key: element.atividadeId } ,
          evento: { 
            dateTime: e.dateTime.toDate(),
            local: e.local
          } 
        } ]
      })
    }
    return val;
  }, [])
  
  .sort((a, b) => {
    let stringA = moment(a.evento.dateTime).format('MM DD YYYY HH:mm');
    let stringB = moment(b.evento.dateTime).format('MM DD YYYY HH:mm');
    return stringA.localeCompare(stringB);
  })
  
  .reduce((val, element) => {
    let aDate = moment(element.evento.dateTime).format('DD/MM/YYYY');
    (val[aDate] = val[aDate] || []).push(element);
    return val;
  }, {});

  return result;

}
