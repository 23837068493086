import React from 'react';
import AtividadeDestaque from '../AtividadeDestaque/AtividadeDestaque';
import "./AreaDestaques.css"


export default function AreaDestaques ({ nextActs, title }) {
  // console.log("🚀 ~ AreaDestaques ~ nextActs:", nextActs.length)
  return (
    <div key={`nextArea` + title}>
      { !!title && <h5 class="destaque_title">{title}</h5> } 
      <div className="containerAreaDestaques">  
        { 
          nextActs && nextActs !== undefined && nextActs.length > 0 && nextActs.map(act => {
            return <AtividadeDestaque key={act.key} item={act} index={act.key} idAgenda={act.atividade.idAgenda} />
          }) 
        }
      </div>
    </div>
  );
}
