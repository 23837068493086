import React, { useContext } from "react"
import { connect } from "react-redux";
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkerAlt, faClock } from '@fortawesome/free-solid-svg-icons'
import mainColors from '../../constants/color'
import moment from 'moment'

import './AtividadeDestaque.css'
import { LazyLoadImage } from "react-lazy-load-image-component"
import * as S from "./styles"

import { setAtividade } from "../../actions/modalActivityActions"


const tituloAtividade = (atividade) => {
  const local = atividade?.origemArtista ? `(${atividade.origemArtista})` : '';
  if (atividade.nome === atividade.grupoArtista) 
    return (
      <div className="containerTitle">
        <div className="title">{atividade.nome} {local}</div>
      </div>
    )
  else {
    return (
      <div className="containerTitle">
        <div className="title">{atividade.nome}</div>
        <small><i>{atividade.grupoArtista} {local}</i></small>
      </div>
    )
  } 
}

const AtividadeDestaque = ({ item, index, agenda, idAgenda, enviarAtividade }) => {
  // console.log("🚀 ~ AtividadeDestaque ~ enviarAtividade:", enviarAtividade)
  // console.log("🚀 ~ AtividadeDestaque ~ idAgenda:", idAgenda)
  // console.log("🚀 ~ AtividadeDestaque ~ agenda:", agenda)
  // console.log("🚀 ~ AtividadeDestaque ~ index:", index)
  // console.log("🚀 ~ AtividadeDestaque ~ item:", item)

  // console.log("🚀 ~ file: AtividadeDestaque.js:34 ~ AtividadeDestaque ~ item, index, agenda, idAgenda", item, index, agenda, idAgenda)

  const dateTime = moment(item.evento.dateTime)
  let horas = dateTime.format('HH[h]');
  let minutos = dateTime.format('mm');

  const horaString = minutos !== '00' ? horas + minutos : horas;

  const dia = `${dateTime.format("DD/MM/yyyy (ddd), ")} ${horaString}`

  const idOnAgenda = !!agenda ? agenda.id : idAgenda

  return (
    <S.ContainerAtividadeDestaque key={index}>

      {/* <S.AtividadeLink
        to={{
          pathname: `/agenda/${idOnAgenda}/atividade/${item.atividade.atividadeId}`,
          state: {
            atividade: !idAgenda ? item.atividade : null, 
            agenda: agenda, 
            index: index
          }
        }} 
        > */}

        {/* <S.AtividadeLink2 onClick={modal.setActivityContent(item.atividade, agenda)}> */}
        <S.AtividadeLink2 onClick={() => enviarAtividade(item.atividade.id, idAgenda)}>
        {/* <S.AtividadeLink2 onClick={()=> { }}> */}

          <LazyLoadImage 
            src={item.atividade.imagem ? item.atividade.imagem : require('../../assets/images/capaplaceholder.jpg')}
            width="100%"
            style={{minHeight: "10rem", objectFit: "cover", height: "12rem"}}
            // height="200"
            // height="200"
            // className="agendaCoverStyle"
            // effect="blur"  
            placeholderSrc={require('../../assets/images/capaplaceholder.jpg')}
            alt={`imagem divulgação da atividade ${item.atividade.nome}`}
          />

          <S.ContainerDescription>
            
            {tituloAtividade(item.atividade)}
            
            <div className='pb-2'>
              <div><FontAwesomeIcon size="xs" color={mainColors.orangeBNB} icon={faClock} fixedWidth/> <small>{dia}</small></div>
              <div><FontAwesomeIcon size="xs" color={mainColors.orangeBNB} icon={faMapMarkerAlt} fixedWidth/> <small>{item.evento.local}</small></div>
            </div>
            <div className=''>
              
            </div>

            <div className="grey-title"><small>{item.atividade.area.toLowerCase()}</small></div>
            <div className="grey-title"><small>{item.atividade.programa.toLowerCase()}</small></div>
            
          </S.ContainerDescription>
        </S.AtividadeLink2>
      {/* </S.AtividadeLink> */}
      
    </S.ContainerAtividadeDestaque>
  )
}

// export default AtividadeDestaque


const mapStateToProps = state => ({
  authUser: state.sessionState.authUser
});


const mapDispatchToProps = (dispatch, props) => ({
  
  enviarAtividade: () => dispatch(setAtividade(props.idAgenda, props.index)),
  
});

export default connect(mapStateToProps, mapDispatchToProps)(AtividadeDestaque);

