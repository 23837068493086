import { userConstants } from '../constants/usersConstants';

let user = JSON.parse(localStorage.getItem('user'));
// let user = JSON.parse(sessionStorage.getItem('user'));
// console.log("🚀 ~ file: session.js:4 ~ user:", user)
const initialState = user ? { loggedIn: true, authUser: user, isCompleted: false, breadcombCompleted: false } : { isCompleted: false, breadcombCompleted: false };
// console.log("🚀 ~ file: session.js:6 ~ initialState:", initialState)



const sessionReducer = (state = initialState, action) => {
  
  switch (action.type) {
    
    case userConstants.LOGIN_REQUEST:
      return {
        ...state,
        loggingIn: true,
      };
    case userConstants.LOGIN_SUCCESS:
      return {
        ...state,
        loggedIn: true,
        authUser: action.authUser
      };

    case userConstants.PROFILE_COMPLETE:
      return {
        ...state, 
        isCompleted: action.isCompleted
      };
    case userConstants.LOGIN_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case userConstants.LOGOUT:
      return { isCompleted: false };

    case userConstants.BREADCOMB_RESET:
      return {
        ...state,
        breadcombCompleted: false,
        breadcombAgenda: undefined,
        breadcombCCBNB: undefined,

      };

    case userConstants.BREADCOMB_SUCCESS_CCBNB:
      return {
        ...state,
        breadcombCompleted: true,
        breadcombCCBNB: action.ccbnb
      };

    default: 
      return state;
      
  }
}

export default sessionReducer;