import React, { Component, useState } from 'react';
import { agendafire } from '../firebase';
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import * as routes from '../constants/routes'

import ReactLoading from 'react-loading';

import mainColors from '../constants/color'
import ScrollManager from './ScrollManager';
import AlertToLogin from './AlertToLogin'

import { LazyLoadImage } from "react-lazy-load-image-component";

import AgendaCover from '../components/AgendaCover'

import InfiniteScroll from 'react-infinite-scroller';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faArrowCircleDown } from '@fortawesome/free-solid-svg-icons'
import { getNextInCacheByCCBNB, getNextInCacheByAreaByCCBNB } from '../firebase/agendafirestore';
import AtividadeAgenda from './AtividadeAgenda/AtividadeAgenda';
import AtividadeDestaque from './AtividadeDestaque/AtividadeDestaque';
import AreaDestaques from './AreaDestaques/AreaDestaques';
import { breadCombReset, saveBreadcombCCBNB } from '../actions/sessionActions';

import ModalActivity from './ModalActivity/ModalActivity';
import AtividadeSimples from './AtividadeSimples';
import { toogleModal } from '../actions/modalActivityActions';

import { trackFinishedEvents } from '../services/lastActivities';
import { getAgendaData } from '../services/agendaService';


const ccbnb = localStorage.getItem('ccbnb');
const localStorageNextsName = ccbnb ? `storedNext-${ccbnb.toUpperCase()}` : null;
let localStorageNexts = ccbnb ? JSON.parse(localStorage.getItem(localStorageNextsName)) : null;
// console.log("🚀 ~ localStorageNextsName:", localStorageNextsName)

const INICIAL_STATE = {
  // agendas: []
  open: false,
  agenda: undefined, 
  atividade: undefined,

  loading: true,
  hasMore: false, 
  // nextActs: [],
  nextActs: !!localStorageNexts  ? localStorageNexts : [],
  nextActsByArea: null,

};

let unsubscribeAgendaListener;
let unsubscribeNextListener;
let unsubscribeNextByAreaListener;

const cleanAreaNamesOnNextArrayByArea = (nextArray) => {
  
  if (nextArray['Audiovisual']) {
    let resultArr =  { ...nextArray, 'Cinema': nextArray['Audiovisual']}
    const { Audiovisual, ...rest} = resultArr
    return {...rest }
  }
  
  return nextArray

}


class HomePage extends Component {

  constructor(props) {
    super(props)
    this.state = { ...INICIAL_STATE };
  }
  

  componentDidMount = async () => {

    

    this.setState({ ...this.state, loading: true });
    const { setBreadcombCCBNB } = this.props

    const ccbnb = this.props.match.params.ccbnb
    this.setState({ ...this.state, ccbnb })
    setBreadcombCCBNB(ccbnb)

    localStorage.setItem('ccbnb', ccbnb)

    
    // MANAGING STORAGES
    const localStorageAgendasName = `storedAgendas-${ccbnb.toUpperCase()}`;
    let localStoredAgendas = localStorage.getItem(localStorageAgendasName);
    
    if (localStoredAgendas) {
      // console.log("🚀 ~ file: Home.js:85 ~ localStoredAgendas", localStoredAgendas)
      const storedAgendas = JSON.parse(localStoredAgendas);
      // console.log("🚀 ~ componentDidMount= ~ storedAgendas[0].id:", storedAgendas[0].id)

      // get last agenda data and store locally
      // getAgendaData(storedAgendas[0])

      this.setState({ ...this.state, agendas: storedAgendas, loading: false, TROLLING: true });

    }

    // storing nexts
    // const localStorageNextsName = `storedNext-${ccbnb.toUpperCase()}`;
    // const localStorageNexts = localStorage.getItem(localStorageNextsName);

    // storing nexts By Area
    const localStorageNextsByAreaName = `storedNextByArea-${ccbnb.toUpperCase()}`;
    const localStorageNextsByArea = localStorage.getItem(localStorageNextsByAreaName);
    
    if (localStorageNexts) {  
      // let parsedNexts = JSON.parse(localStorageNexts);
      // this.setState({ ...this.state, nextActs: parsedNexts });
      this.setState({ ...this.state, nextActs: localStorageNexts });
    }

    if (localStorageNextsByArea) {  
      let parsedNexts = JSON.parse(localStorageNextsByArea);
      this.setState({ ...this.state, nextActsByArea: cleanAreaNamesOnNextArrayByArea(parsedNexts) });
    }

    // if (navigator.onLine && ccbnb && ccbnb !== undefined) {
    if (ccbnb && ccbnb !== undefined) {

      unsubscribeNextByAreaListener = agendafire.getNextInCacheByAreaByCCBNB(ccbnb.toLowerCase()).onSnapshot(
        (snapshot) => {
          // put snapshot docs data on an array
          if (!snapshot.empty && !!snapshot.data()) {
            const data = snapshot.data();

            let parsedNexts = JSON.parse(data.last);
            this.setState({...this.state, nextActsByArea: cleanAreaNamesOnNextArrayByArea(parsedNexts) });
            
            localStorage.setItem(localStorageNextsByAreaName, data.last); 
            
            let res = {}
            Object.keys(parsedNexts).forEach(element => {
              res[element] = trackFinishedEvents(parsedNexts[element])
            });

            localStorage.setItem(localStorageNextsByAreaName,JSON.stringify(res)); 
            this.setState({ ...this.state, nextActsByArea: cleanAreaNamesOnNextArrayByArea(res) });

          }
        }, 
        (error) => console.error('Erro: ', error)
        
      );

      unsubscribeNextListener = agendafire.getNextInCacheByCCBNB(ccbnb.toLowerCase()).onSnapshot(
        (snapshot) => {
          
          // put snapshot docs data on an array
          if (!!snapshot && !snapshot.empty && !!snapshot.data()) {
            const data = snapshot.data();

            let parsedNexts = JSON.parse(data.last);
            parsedNexts = trackFinishedEvents(parsedNexts)
            
            this.setState({...this.state, nextActs: parsedNexts });
            
            localStorage.setItem(localStorageNextsName, data.last); 

          }
        }, 
        (error) => console.error('Erro: ', error)
        
      );

      unsubscribeAgendaListener = agendafire.getLastAgendasByCCBNB(ccbnb.toLowerCase()).onSnapshot(
        (snapshot) => {
          // put snapshot docs data on an array
          if (snapshot.empty) {
            this.setState({...this.state, agendas: [], hasMore: false });
            
          } else {
            const data = snapshot.docs.map(function (documentSnapshot) {
              return documentSnapshot.data();
            });
            const lastAgenda = snapshot.docs[snapshot.docs.length - 1];
            const firstAgenda = snapshot.docs[0];
            this.setState({...this.state, agendas: data, loading:false, lastAgenda, firstAgenda, hasMore: true });
            // this.setState({...this.state, agendas: data, loading:false, lastAgenda, firstAgenda });
            localStorage.setItem(localStorageAgendasName, JSON.stringify(data)); 
            
            // poolling first agenda's data
            getAgendaData(data[0])

          }
        }, 
        (error) => console.error('Erro: ', error)
        
      );
    }
    

  };

  componentWillUnmount() {
    this.props.resetBreadcomb()

    if (navigator.onLine) {
      
      if (unsubscribeAgendaListener && unsubscribeAgendaListener !== undefined)
        unsubscribeAgendaListener();
  
      if (unsubscribeNextListener && unsubscribeNextListener !== undefined)
        unsubscribeNextListener();
  
      if (unsubscribeNextByAreaListener && unsubscribeNextByAreaListener !== undefined)
        unsubscribeNextByAreaListener();
      
    } 

    
  }
  
  loadingMoreAgendasFoward = async () => {
    const { ccbnb } = this.state; 
    
    const previousLastAgenda = this.state.lastAgenda;

    agendafire.getAgendasWithPaginationAfter(this.state.lastAgenda, ccbnb).get().then(
      
      (snapshot) => {
        
        if (snapshot.empty) {
          this.setState({...this.state, foward: false });
        } else {
          const data = snapshot.docs.map(function (documentSnapshot) {
            return documentSnapshot.data();
          });
          
          const firstAgenda = snapshot.docs[0];
          const lastAgenda = snapshot.docs[snapshot.docs.length - 1];

          this.setState({...this.state, agendas: data, lastAgenda, previousLastAgenda, firstAgenda, back: true });
          
        }
    
      }

    );
  }
  

  loadingMoreAgendasBack = async () => {

    agendafire.getAgendasWithPaginationBefore(this.state.firstAgenda, this.state.ccbnb).onSnapshot(
      
      (snapshot) => {
        if (snapshot.empty) {
          this.setState({...this.state, back: false });
        } else {
          const data = snapshot.docs.map(function (documentSnapshot) {
            return documentSnapshot.data();
          });

          const firstAgenda = snapshot.docs[snapshot.docs.length - 1];
          const lastAgenda = snapshot.docs[0];

          this.setState({...this.state, agendas: data.reverse(), lastAgenda, firstAgenda, foward: true });
        }
        
      }

    );
  }

  loadingInfinite = async () => {
    const { ccbnb, lastAgenda } = this.state; 
    
    if (lastAgenda && ccbnb && navigator.onLine ) {
      
      agendafire.getAgendasWithPaginationAfter(lastAgenda, ccbnb).get().then(
        (snapshot) => {
          if (snapshot.empty) {
            this.setState({...this.state, hasMore: false });
          } else {
            const data = snapshot.docs.map(function (documentSnapshot) {
              return documentSnapshot.data();
            });
            const lastAgenda = snapshot.docs[snapshot.docs.length - 1];
            // const newAgendas = { ...this.state.agendas, ...data};
            const newAgendas = this.state.agendas.concat(data);
            this.setState({...this.state, agendas: newAgendas, lastAgenda }); 
          }
        }, 
        (error) => console.log('Error loading: ', error)
      );
    }

    
  }

  
  render() {

    const { agendas, ccbnb, hasMore, nextActs, nextActsByArea, open } = this.state;
    // console.log("🚀 ~ file: Home.js:244 ~ render ~ this.state", this.state)
    

    const loader = 

      <div key={0} style={{ paddingBottom: 50, minHeight: '60vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', }}>
        <ReactLoading type={'spin'} color={mainColors.orangeBNB} height={'20%'} width={'20%'} />  
      </div>
  
    
    return (
      // <div style={{ width: '70vw', marginLeft: 'auto', marginRight: 'auto'}}>
      // <div className="pt-3">
      <div className="centered_container pt-3">
        {/* <div className=" py-3 pull-right">
            <Link to={routes.LANDING} className="btn btn-lg  btn-outline-primary">            
              <FontAwesomeIcon icon={faArrowLeft} />{' '}
              Escolher outro CCBNB
            </Link>
        </div> */}

        <AlertToLogin />

        {/* { ccbnb && <h5 className="text-center py-2">Últimas Agendas - {ccbnb.toUpperCase()}</h5> } */}

        {/* <ModalActivity active={open} hideModal={() => this.setState({ ...this.state, open: !open } )}> */}
        <ModalActivity active={this.props.openModal} hideModal={() => this.props.toogle()}>
          {/* <AtividadeSimples agenda={this.state.agenda} atividade={this.state.atividade} isFav={false} /> */}
          <AtividadeSimples fetching={this.props.modalFetching} error={this.props.modalError} agenda={this.props.atividadeModal?.idAgenda} atividade={this.props.atividadeModal} isFav={false} />
        </ModalActivity>
        
        

        <InfiniteScroll
          pageStart={1}
          loadMore={this.loadingInfinite}
          initialLoad={true}
          hasMore={hasMore}
          loader={loader}
          threshold={200}
          // loader={this.loadingDiv}
        >

      {/* { !!nextActs && nextActs && <div key="humhum">Nexts: {nextActs}</div> } */}

      { !!nextActs && nextActs !== undefined && 
        <AreaDestaques nextActs={nextActs} title={"Próximas Atividades"} />
      } 

      {/* { 
        !!nextActsByArea && nextActsByArea !== undefined && Object.keys(nextActsByArea).sort().map(area => {
          return (<div key={`nextArea`+ area}>
            <h5 className="">{area}</h5>
            <AreaDestaques nextActs={nextActsByArea[area]} />
          </div>)
        })
      }  */}

      { 
        !!nextActsByArea && nextActsByArea !== undefined && Object.keys(nextActsByArea).sort().map(area => <AreaDestaques title={area} nextActs={nextActsByArea[area]} />)
      } 

        <ScrollManager scrollKey={"home"} />
        

        {/* <div className="container">  

            { 
              nextActs && nextActs !== undefined && nextActs.map(act => {
                
                return <AtividadeDestaque key={act.key} item={act} index={act.key} idAgenda={act.atividade.idAgenda} />
              }) 
            }
        </div> */}

        {/* <ScrollManager scrollKey=agendas{"home"} />
        <div>
          <h5>Próximas Atividades</h5>
          <AreaDestaques nextActs={nextActs} />
        </div> */}
  

        <div className="container">  
          { ccbnb && <h5 className="text-center py-2">Agendas do CCBNB-{ccbnb.toUpperCase()}</h5> }
          <div style={{ paddingBottom: 50, display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap' }}>
            <ScrollManager scrollKey={"home"} />
            <div className='card-deck justify-content-md-center align-items-center'>

              { agendas && agendas !== undefined && agendas.map(agenda => 
                
                <AgendaCover agenda={agenda} key={agenda.id} />
              )}

              { agendas && agendas.length === 0 && 
      
                <div className="pt-3 text-center">
                  <h5>Não há agendas disponíveis no momento. </h5> 
                </div>
    
              } 

            </div>
          </div>
          { hasMore && 
            <div style={{ padding: 50, textAlign: 'center', color: mainColors.redBNB, fontSize: '1rem' }} >
              <FontAwesomeIcon icon={faArrowCircleDown} />{' '}
              {/* Agendas mais antigas */}
              {/* Mais agendas */}
              Descer para mais agendas
            </div> 
          }
        </div>
        </InfiniteScroll>

      </div>
    )


  }
}

const mapStateToProps = state => ({
  authUser: state.sessionState.authUser,
  atividadeModal: state.modalState.atividadeModal,
  openModal: state.modalState.openModal,
  modalFetching: state.modalState.modalFetching, 
  modalError: state.modalState.modalError
});

// export default connect(mapStateToProps)(HomePage);

const mapDispatchToProps = (dispatch, props) => ({
  setBreadcombCCBNB: (ccbnb) => dispatch(saveBreadcombCCBNB(ccbnb)),
  resetBreadcomb: () => dispatch(breadCombReset()),
  toogle: () => dispatch(toogleModal())
  
});

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);

