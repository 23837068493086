import { LazyLoadImage } from 'react-lazy-load-image-component';
import styled, { css } from 'styled-components';
import media from "styled-media-query";

export const ModalBlock = styled.div`
  align-items: center;
  bottom: 0;
  justify-content: center;
  left: 0;
  overflow: hidden;
  padding: 0.4rem;
  position: fixed;
  right: 0;
  top: 0;
  display: flex;
  /* opacity: 1; */
  opacity: ${({active}) => (active ? 1 : 0)};
  transition: all 0.25s ease-in-out;
  /* transition: all 0.5s ease 1; */
  /* display: ${({active}) => (active ? 'flex' : 'none')}; */
  z-index: ${({active}) => (active ? 400 : -11)};
  /* z-index: 400; */
  /* cursor: auto; */
`;

export const ModalOverlay = styled.a`
  background: rgba(247, 248, 249, 0.75);
  bottom: 0;
  cursor: default;
  display: block;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
`;

export const ModalClose = styled.a`
  float: right !important;
  text-decoration: none !important;
  cursor: pointer;
  font-size: 1rem;
`;

export const Container = styled.div`
  padding: 0 0.8rem;
  width: 100%;

  height: ${({fetching}) => (fetching ? 0 : "100%")};
  
`;

export const ModalBody = styled.div`
  overflow-y: auto;
  /* padding: 30px 10px; */
  position: relative;
`;

export const ModalHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: #303742;
  /* padding: 20px 5px 10px 5px; */
  padding: 10px 5px 10px 5px;
`;

export const ModalTitle = styled.span`
  font-size: 30px;
  font-weight: 500;
`;

export const ModalFooter = styled.div`
  padding: 10px 0px;
  text-align: right;
`;

export const Button = styled.button`
  background: #7b2cbf;
  color: white;
  font-size: 1em;
  margin: 10px;
  padding: 5px 10px;
  border: 2px solid #7b2cbf;
  border-radius: 3px;
  cursor: pointer;
`;

export const ActivityLazyLoadImage = styled(LazyLoadImage)`
  min-height: 8rem;
  object-fit: cover;
  /* height: 18rem; */
  /* max-height: 28rem; */
  width: 100%;

  ${media.lessThan("large")`
    max-height: 23rem;
    width: 100%;
  `}

`

export const TitleContainer = styled.div`
  padding-top: 20px;
  padding-bottom: 10px;
  /* font-size: 10rem; */
  /* font-weight: bold; */
`

export const Title = styled.h1`
  /* padding-top: 20px; */

  font-size: 1.5rem;
  font-weight: bolder;
  margin-bottom: 0;
`

export const Artist = styled.div`
  /* padding-top: 20px; */
  /* font-size: 10rem; */
  /* font-weight: bold; */
  line-height: 1.2rem;
`
