import { dbfire } from './firebase';
import moment from 'moment';

export const onceGetAgenda = async (id) => {
  // await dbfire.collection('agenda').doc(id).get().then(snap => snap.data());
  const result = await dbfire.collection('agenda').doc(id).get();
  return result.data();
}

export const getActivity = async (agendaId, atividadeId) => {
  // await dbfire.collection('agenda').doc(agendaId).collection('atividades').doc(atividadeId).get().then(snap => snap.data());  
  const result = await dbfire.collection('agenda').doc(agendaId).collection('atividades').doc(atividadeId).get().catch(()=> "Houve um erro");
  return result.data();
}

export const getActivitySimple = (agendaId, atividadeId) => {
  return dbfire.collection('agenda').doc(agendaId).collection('atividades').doc(atividadeId)
}
  

export const getActivities = (id) =>
  // dbfire.collection('agenda').doc(id).collection('atividades').orderBy('area').get();
  dbfire.collection('agenda').doc(id).collection('atividades').orderBy('area');


export const getDays = (id) =>
  // dbfire.collection('agenda').doc(id).collection('dias').get();
  dbfire.collection('agenda').doc(id).collection('dias');


export const getLastAgendas = () => 
  // dbfire.collection('agenda').orderBy('mes', 'desc').where('liberada', '==', true).limit(3).get();
  dbfire.collection('agenda').orderBy('mes', 'desc').where('liberada', '==', true).limit(3);

export const getNextInCache = async (ccbnb) => {
  let data = await dbfire.collection('proxs').doc('cache').get();
  data = JSON.parse(data.data().last)
  return data; 
  // console.log("🚀 ~ file: agendafirestore.js:34 ~ getNextInCache ~ data", data)
  
  // let result = []

  // data.forEach(x => {
  //   // console.log("🚀 ~ file: agendafirestore.js:38 ~ getNextInCache ~ x", x)
    
  //   let sanitized_event = x.atividade.eventos.map(e => { 
  //     console.log("🚀 ~ file: agendafirestore.js:47 ~ getNextInCache ~ e", e)  
  //     return {
  //       ...e, dateTime: moment(e.dateTime).
  //       // ...e, dateTime: new Date(e.dateTime._seconds)
  //     }
  //   })
    
  //   console.log("🚀 ~ file: agendafirestore.js:43 ~ getNextInCache ~ sanitized_event", sanitized_event)
  //   let atividade = { ...x.atividade, eventos: sanitized_event }
    
  //   result.push({ ...x, atividade: atividade })
  // })

  // return result
}

export const getNextInCacheByCCBNB = (ccbnb) => 
  dbfire.collection('proxs').doc(ccbnb)


export const getNextInCacheByAreaByCCBNB = (ccbnb) => 
  dbfire.collection('proxsByArea').doc(ccbnb)


export const getAgendasWithPaginationAfter = (lastVisibleAgenda, ccbnb) => 
  dbfire.collection('agenda')
    .orderBy('mes', 'desc')
    .where('ccbnb', '==', ccbnb.toLowerCase())
    .where('liberada', '==', true)
    .startAfter(lastVisibleAgenda)
    .limit(3);

// using another index to get previous 3 agendas
export const getAgendasWithPaginationBefore = (previousFirstAgenda, ccbnb) => 
    dbfire.collection('agenda')
      .orderBy('mes', 'asc')
      .where('ccbnb', '==', ccbnb.toLowerCase())
      .where('liberada', '==', true)
      .startAfter(previousFirstAgenda)
      .limit(3);

export const getLastAgendasByCCBNB = (ccbnb) => 
  dbfire.collection('agenda')
    .orderBy('mes', 'desc')
    .where('ccbnb', '==', ccbnb)
    .where('liberada', '==', true)
    .limit(3);


export const getLastTwoAgendasByCCBNB = (ccbnb) => 
  dbfire.collection('agenda')
    .where('ccbnb', '==', ccbnb.toLowerCase())
    .where('liberada', '==', true)
    .orderBy('mes', 'desc')
    .limit(2);
  
